import axios from "axios";
import store from "@/store";
import router from "@/router/index";

// 1. 기본 설정

const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  // timeout: 5000,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  if (token !== null) {
    config.headers["Authorization"] = "token " + token;
    config.headers["Access-Control-Allow-Origin"] = "*";
  } else {
    router.push({ name: "sign-in" });
  }
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response.status;
    if (status === 401) {
      store.commit("removeToken");
      router.push({ name: "sign-in" });
    }
    return Promise.reject(error);
  }
);

export default instance;
