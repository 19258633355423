import qscAxios from "@/api/qsc-axios.js";

export default {
  //회사관리
  getCompanyList(param) {
    return qscAxios({
      url: "/companies",
      method: "get",
      params: param,
    });
  },

  getCompany(id) {
    return qscAxios({
      url: "/companies/" + id,
      method: "get",
    });
  },

  updateCompany(cmp) {
    return qscAxios({
      url: "/companies/" + cmp.id,
      method: "post",
      data: cmp,
    });
  },

  updateCompanyFile(result, id) {
    return qscAxios({
      url: "/companies/" + id,
      method: "post",
      data: result,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  newCompany(cmp) {
    return qscAxios({
      url: "/companies",
      method: "post",
      data: cmp,
    });
  },

  //브랜드관리
  getBrandList(param) {
    return qscAxios({
      url: "/brands",
      method: "get",
      params: param,
    });
  },

  getBrand(id) {
    return qscAxios({
      url: "/brands/" + id,
      method: "get",
    });
  },

  updateBrand(brand) {
    return qscAxios({
      url: "/brands/" + brand.id,
      method: "post",
      data: brand,
    });
  },

  newBrand(brand) {
    return qscAxios({
      url: "/brands",
      method: "post",
      data: brand,
    });
  },

  //사용자 관리
  getUserList(param) {
    return qscAxios({
      url: "/users",
      method: "get",
      params: param,
    });
  },

  getUser(id) {
    return qscAxios({
      url: "/users/" + id,
      method: "get",
    });
  },

  updateUser(user) {
    return qscAxios({
      url: "/users/" + user.id,
      method: "post",
      data: user,
    });
  },

  newUser(user) {
    return qscAxios({
      url: "/users",
      method: "post",
      data: user,
    });
  },

  //사업자 관리
  getBusinessesList(param) {
    return qscAxios({
      url: "/businesses",
      method: "get",
      params: param,
    });
  },

  getBusiness(id) {
    return qscAxios({
      url: "/businesses/" + id,
      method: "get",
    });
  },

  updateBusiness(business) {
    return qscAxios({
      url: "/businesses/" + business.id,
      method: "post",
      data: business,
    });
  },

  newBusiness(business) {
    return qscAxios({
      url: "/businesses",
      method: "post",
      data: business,
    });
  },

  //매장 관리
  getStoreList(param) {
    return qscAxios({
      url: "/stores",
      method: "get",
      params: param,
    });
  },

  getStore(id) {
    return qscAxios({
      url: "/stores/" + id,
      method: "get",
    });
  },

  updateStore(store) {
    return qscAxios({
      url: "/stores/" + store.id,
      method: "post",
      data: store,
    });
  },

  newStore(store) {
    return qscAxios({
      url: "/stores",
      method: "post",
      data: store,
    });
  },

  //점검항목 관리
  getCheckItemList(param) {
    return qscAxios({
      url: "/check-items",
      method: "get",
      params: param,
    });
  },

  newCheckItem(checkitem) {
    return qscAxios({
      url: "/check-items",
      method: "post",
      data: checkitem,
    });
  },

  getCheckItem(id) {
    return qscAxios({
      url: "/check-items/" + id,
      method: "get",
    });
  },
  updateCheckItem(checkitem) {
    return qscAxios({
      url: "/check-items/" + checkitem.id,
      method: "post",
      data: checkitem,
    });
  },

  //점검항목 기준 관리
  getCheckVersionList(param) {
    return qscAxios({
      url: "/check-versions",
      method: "get",
      params: param,
    });
  },

  newCheckVersion(checkversion) {
    return qscAxios({
      url: "/check-versions",
      method: "post",
      data: checkversion,
    });
  },

  getCheckVersion(id) {
    return qscAxios({
      url: "/check-versions/" + id,
      method: "get",
    });
  },

  updateCheckVersion(checkversion) {
    return qscAxios({
      url: "/check-versions/" + checkversion.id,
      method: "post",
      data: checkversion,
    });
  },

  //사업자 등록 번호 검색
  getBusinessNumberList(param) {
    return qscAxios({
      url: "/businesses",
      method: "get",
      params: param,
    });
  },

  //점검일정 관리
  getScheduleList(param) {
    return qscAxios({
      url: "/schedules",
      method: "get",
      params: param,
    });
  },

  getSchedule(id) {
    return qscAxios({
      url: "/schedules/" + id,
      method: "get",
    });
  },

  newSchedule(schedule) {
    return qscAxios({
      url: "/schedules",
      method: "post",
      data: schedule,
    });
  },

  updateSchedule(schedule) {
    return qscAxios({
      url: "/schedules/" + schedule.id,
      method: "post",
      data: schedule,
    });
  },

  getregStoreList(param) {
    return qscAxios({
      url: "/schedule-register-store",
      method: "get",
      params: param,
    });
  },

  //코드값 매핑
  getCodeList() {
    return qscAxios({
      url: "/cores/code-detail",
      method: "get",
    });
  },

  // QSC 점검하기
  getQscCheckList(id, param) {
    return qscAxios({
      url: "/check-result-version/" + id,
      method: "get",
      params: param,
    });
  },

  getQscCheck(id, itemid) {
    return qscAxios({
      url: "/check-result-version/" + id + "/check_item/" + itemid,
      method: "get",
    });
  },

  // result-id 적용
  getQscItem(id, itemid) {
    return qscAxios({
      url: "/inspections/" + id + "/check-items/" + itemid,
      method: "get",
    });
  },

  newQscCheck(result) {
    return qscAxios({
      url: "/check-result-detail",
      method: "post",
      data: result,
    });
  },

  newQscCheckFile(result) {
    return qscAxios({
      url: "/check-result-detail",
      method: "post",
      data: result,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  saveQscAnswerItem(inspectionId, questionId, questionItemId, payload) {
    return qscAxios({
      url:
        "/inspections/" +
        inspectionId +
        "/questions/" +
        questionId +
        "/items/" +
        questionItemId,
      method: "patch",
      data: payload,
    });
  },

  updateQscCheck(result) {
    return qscAxios({
      url: "/check-result-detail/" + result.check_result,
      method: "post",
      data: result,
    });
  },

  updateQscCheckFile(result, id) {
    return qscAxios({
      url: "/check-result-detail/" + id,
      method: "post",
      data: result,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  finalResult(result, id) {
    return qscAxios({
      url: "/check-results/" + id,
      method: "post",
      data: result,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  //점검 결과 관리
  getResultList(param) {
    return qscAxios({
      url: "/check-results",
      method: "get",
      params: param,
    });
  },

  getCheckResultDetail(id) {
    return qscAxios({
      url: "/check-results/" + id,
      method: "get",
    });
  },

  //점검 상세 현황
  getResultDetail(param) {
    return qscAxios({
      url: "/check-result-detail",
      method: "get",
      params: param,
    });
  },

  // 버전별 점검결과 result-id 적용
  getResultCheckList(id, param) {
    return qscAxios({
      url: "/inspections/" + id,
      method: "get",
      params: param,
    });
  },

  // 카테고리
  getCategoryList(param) {
    return qscAxios({
      url: "/category",
      method: "get",
      params: param,
    });
  },

  getCategory(id) {
    return qscAxios({
      url: "/category/" + id,
      method: "get",
    });
  },

  newCategory(category) {
    return qscAxios({
      url: "/category",
      method: "post",
      data: category,
    });
  },

  updateCategory(category) {
    return qscAxios({
      url: "/category/" + category.id,
      method: "post",
      data: category,
    });
  },

  //카테고리 하이라키
  getCategoryHierarchy(param) {
    return qscAxios({
      url: "/category-hierarchy",
      method: "get",
      params: param,
    });
  },
};
